#services {
    position: relative;
    border-bottom: 1px solid var(--color-black);
}

.services-list {
    padding: 0;
}

#services dl {
    height: 100%;
    padding: 14vw 1vw;
    text-align: center;
}

#services dt svg {
    display: block;
    max-height: 1.5em;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.5em;
}

#services dl:first-of-type {
    background-color: var(--color-blue);
    background-image: linear-gradient(to bottom, #193f60, var(--color-blue) 35%);
}

#services dl:nth-of-type(2) {
    background-color: rgb(248, 248, 248);
    background-image: linear-gradient(135deg, #c1c1c1, #fff 35%);
    color: #5e5e5e;
}

#services dl:nth-of-type(3) {
    background-color: rgb(51, 117, 173);
    background-image: linear-gradient(to right, rgb(51, 117, 173), rgb(35, 86, 125));
}

#services dl:nth-of-type(4) {
    background-color: var(--color-white);
    color: var(--color-blue);
}

#services dl:nth-of-type(5) {
    background-color: var(--color-blue);
    color: var(--color-white);
}

#services dl:nth-of-type(6) {
    background-image: url("../../img/photo-switzerland-mountains-portrait.jpg");
    background-size: cover;
    background-repeat: no-repeat;
}


#services dt {
    width: max-content;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2em;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 1.3em;
}

#services dd {
    width: max-content;
    margin-bottom: 1.5em;
    margin-left: auto;
    margin-right: auto;
    letter-spacing: 1px;
}

@media (min-width: 800px) {
    #services dl {
        padding: 7vw 1vw;
    }    
}
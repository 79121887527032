.blue-layout {
    position: relative;
    color: var(--color-white);
    background-color: var(--color-blue);
    background-image: linear-gradient(to right, var(--color-blue) 70%, rgb(42, 99,147));
    border-bottom: 1px solid var(--color-black);
}

.blue-layout:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background-image: url('../../img/schonbachler-pieces.svg?as=jpeg');
    background-size: cover;
    opacity: 0.1;
}

.blue-layout h2 {
    font-size: 1.5em;
    font-weight: 600;
}

.blue-layout p {
    letter-spacing: 1px;
}

.blue-layout p:last-child {
    margin-bottom: 0;
}

.blue-layout h2, .blue-layout p {
    margin-bottom: 2em;
}

.blue-layout picture {
    position: relative;
}

.blue-layout picture a {
    position: absolute;
    z-index: 2;
    top: 7vw;
    left: 7vw;
    display: block;
    width: 1em;
}

@media (min-width: 800px) {
    .blue-layout:after {
        transform: none;
    }

    .blue-layout img {
        height: 100%;
        object-fit: cover;
    }
}
#navigation {
    position: relative;
    background-color: var(--color-white);
    color: var(--color-black);
    border-bottom: 1px solid var(--color-black);
    font-size: 1.2em;
}

#navigation:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background-image: url('../../img/schonbachler-pieces.svg');
    background-size: cover;
    opacity: 0.1;
}

#navigation h2 {
    font-size: .55em;
    margin-bottom: 5em;
}

#navigation ul {
    margin: 0;
    padding-left: 0;
    text-transform: uppercase;
    letter-spacing: 5px;
}

#navigation li:not(:last-of-type) {
    margin-bottom: 1em;
}

#navigation a {
    position: relative;
    font-weight: 400;
    color: var(--color-black);
    text-decoration: none;
    transition: color 1.5s cubic-bezier(0.165, 0.84, 0.44, 1);
}

#navigation a:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: currentColor;
    transition: width .5s cubic-bezier(0.165, 0.84, 0.44, 1);
}

#navigation a:hover {
    color: var(--color-blue);
}

@media (min-width: 800px) {
    #navigation {
        font-size: 1em;
    }
    
    #navigation img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
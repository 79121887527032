#intro {
    position: relative;
    display: grid;
    height: 100vh;
    background-color: rgb(0, 0, 0);
    background-size: cover;
    align-items: center;
}

#intro:before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 100vh;
    background-image: url("../../img/background.jpg");
    background-size: cover;
}

#intro svg {
    position: absolute;
    overflow: visible;
    top: 50%;
    left: 50%;
    width: 90%;
    transform: translate(-50%, -50%);
    opacity: 0;
}

#intro g,
#intro path {
    transform-origin: center;
}

#intro div {
    padding: 7vw;
}

#intro h1 {
    max-width: 11em;
    font-size: 2.3em;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 100;
    line-height: 1.2;
    margin-bottom: 0.5em;
}

#intro p {
    color: var(--color-blue);
}

@media (min-width: 800px) {
    #intro {
        align-items: flex-end;
        min-height: 100vh;
    }

    #intro svg {
        max-width: 90vh;
    }

    #intro h1 {
        font-size: 3em;
    }

}
.animation {
    overflow: hidden;
}

.animation:after {
    transition: transform 40s linear;
}

.animation-active:after,
.animation:hover:after {
    transform: matrix3d(1.1054399999999998, 0.34, 0.17, 0.001, -0.34, 1.1054399999999998, 0.17, -0.001, -0.17, -0.17, 0.9603999999999999, 0, 0, 0, 0, 1);
}

@media (min-width: 800px) {
    .animation:after {
        transition: transform 60s linear;
    }
}
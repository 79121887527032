@font-face {
  font-family: Apex Sans Book;
  font-style: normal;
  font-weight: 100;
  src: url("ApexNew-Light.e7307dca.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: Apex Sans Book;
  font-style: normal;
  font-weight: 400;
  src: url("ApexNew-Book.65eb3a46.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: Apex Sans Book;
  font-style: normal;
  font-weight: 600;
  src: url("ApexNew-Medium.d2615023.woff") format("woff");
  font-display: swap;
}

:root {
  --default-font-size: 16px;
  --color-white: #fff;
  --color-grey: #999;
  --color-black: #101010;
  --color-blue: #008cd1;
  --color-blue-light: #00a1ff;
  --break-mobile: 640px;
}

@media (min-width: 1280px) {
  :root {
    --default-font-size: 18px;
  }
}

@media (min-width: 1600px) {
  :root {
    --default-font-size: 20px;
  }
}

*, :before, :after {
  box-sizing: border-box;
}

html {
  font-size: var(--default-font-size);
  background-color: var(--color-black);
  color: var(--color-white);
  scroll-behavior: smooth;
  font-weight: 600;
}

html:focus-within {
  scroll-behavior: smooth;
}

body, h1, h2, h3, h4, p, li, figure, figcaption, blockquote, dl, dd {
  margin: 0;
}

body {
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
  font-family: Apex Sans Book, sans-serif;
  font-size: 100%;
  font-weight: 600;
  line-height: 1.65;
}

section {
  padding: 14vw 7vw;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

h2 {
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 1em;
  font-size: .9em;
  font-weight: 400;
}

p {
  font-weight: 600;
}

ul[role="list"], ol[role="list"] {
  list-style: none;
}

a:not([class]) {
  -webkit-text-decoration-skip-ink: auto;
  text-decoration-skip-ink: auto;
}

picture {
  height: 100%;
}

img {
  width: 100%;
  height: auto;
  display: block;
}

svg {
  width: 100%;
  height: auto;
}

input, button, textarea, select {
  font: inherit;
}

footer {
  background-color: var(--color-white);
  color: var(--color-grey);
  padding: 14vw 7vw;
  font-size: 12px;
  position: relative;
}

footer p {
  color: var(--color-grey);
  padding: .5em 0;
  font-size: 12px;
  line-height: 1.2;
}

@media (min-width: 800px) {
  section, footer {
    padding: 7vw;
  }
}

@media (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }

  *, :before, :after {
    scroll-behavior: auto !important;
    transition-duration: .01ms !important;
    animation-duration: .01ms !important;
    animation-iteration-count: 1 !important;
  }
}

.grid-1\/6 {
  grid-template-rows: 1fr;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: 0;
  display: grid;
}

@media (min-width: 480px) {
  .grid-1\/6 {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1024px) {
  .grid-1\/6 {
    grid-template-columns: repeat(6, 1fr);
  }
}

@media (min-width: 800px) {
  .grid-1\/2 {
    grid-template: ". ." 1fr
    / 1fr 1fr;
    align-items: center;
    gap: 0;
    display: grid;
  }

  .grid-2\/5 {
    grid-template: ". ." 1fr
    / 2fr 5fr;
    align-items: center;
    gap: 0;
    display: grid;
  }

  .grid-inverted section {
    order: 2;
  }

  .grid-inverted picture {
    order: 1;
  }
}

.animation {
  overflow: hidden;
}

.animation:after {
  transition: transform 40s linear;
}

.animation-active:after, .animation:hover:after {
  transform: matrix3d(1.10544, .34, .17, .001, -.34, 1.10544, .17, -.001, -.17, -.17, .9604, 0, 0, 0, 0, 1);
}

@media (min-width: 800px) {
  .animation:after {
    transition: transform 60s linear;
  }
}

#intro {
  height: 100vh;
  background-color: #000;
  background-size: cover;
  align-items: center;
  display: grid;
  position: relative;
}

#intro:before {
  content: "";
  width: 100%;
  height: 100%;
  max-height: 100vh;
  background-image: url("background.e11b3485.jpg");
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
}

#intro svg {
  width: 90%;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: visible;
  transform: translate(-50%, -50%);
}

#intro g, #intro path {
  transform-origin: center;
}

#intro div {
  padding: 7vw;
}

#intro h1 {
  max-width: 11em;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: .5em;
  font-size: 2.3em;
  font-weight: 100;
  line-height: 1.2;
}

#intro p {
  color: var(--color-blue);
}

@media (min-width: 800px) {
  #intro {
    min-height: 100vh;
    align-items: flex-end;
  }

  #intro svg {
    max-width: 90vh;
  }

  #intro h1 {
    font-size: 3em;
  }
}

#navigation {
  background-color: var(--color-white);
  color: var(--color-black);
  border-bottom: 1px solid var(--color-black);
  font-size: 1.2em;
  position: relative;
}

#navigation:after {
  content: "";
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: .1;
  background-image: url("schonbachler-pieces.0d714c8d.svg");
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
}

#navigation h2 {
  margin-bottom: 5em;
  font-size: .55em;
}

#navigation ul {
  text-transform: uppercase;
  letter-spacing: 5px;
  margin: 0;
  padding-left: 0;
}

#navigation li:not(:last-of-type) {
  margin-bottom: 1em;
}

#navigation a {
  color: var(--color-black);
  font-weight: 400;
  text-decoration: none;
  transition: color 1.5s cubic-bezier(.165, .84, .44, 1);
  position: relative;
}

#navigation a:after {
  content: "";
  width: 100%;
  height: 1px;
  background-color: currentColor;
  transition: width .5s cubic-bezier(.165, .84, .44, 1);
  position: absolute;
  bottom: 0;
  left: 0;
}

#navigation a:hover {
  color: var(--color-blue);
}

@media (min-width: 800px) {
  #navigation {
    font-size: 1em;
  }

  #navigation img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.blue-layout {
  color: var(--color-white);
  background-color: var(--color-blue);
  background-image: linear-gradient(to right, var(--color-blue) 70%, #2a6393);
  border-bottom: 1px solid var(--color-black);
  position: relative;
}

.blue-layout:after {
  content: "";
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: .1;
  background-image: url("schonbachler-pieces.0d714c8d.svg");
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.blue-layout h2 {
  font-size: 1.5em;
  font-weight: 600;
}

.blue-layout p {
  letter-spacing: 1px;
}

.blue-layout p:last-child {
  margin-bottom: 0;
}

.blue-layout h2, .blue-layout p {
  margin-bottom: 2em;
}

.blue-layout picture {
  position: relative;
}

.blue-layout picture a {
  z-index: 2;
  width: 1em;
  display: block;
  position: absolute;
  top: 7vw;
  left: 7vw;
}

@media (min-width: 800px) {
  .blue-layout:after {
    transform: none;
  }

  .blue-layout img {
    height: 100%;
    object-fit: cover;
  }
}

#foreword {
  background-color: var(--color-white);
  color: var(--color-black);
  border-bottom: 1px solid var(--color-black);
  position: relative;
}

#foreword section {
  padding: 0;
}

#foreword div {
  padding: 14vw 7vw;
}

#foreword h2 {
  margin-bottom: 0;
}

#foreword blockquote {
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 1em;
  padding-top: 1em;
  font-size: 1.5em;
  font-weight: 400;
  position: relative;
}

#foreword blockquote:before {
  content: "";
  width: 3em;
  height: .25em;
  background-color: var(--color-blue-light);
  position: absolute;
  top: 0;
  left: 0;
}

#foreword blockquote span {
  color: var(--color-blue);
  font-weight: 600;
}

#foreword blockquote span:before {
  content: "\" ";
  font-style: italic;
}

#foreword blockquote:after {
  content: "\"";
  font-style: italic;
}

#foreword p:first-of-type {
  letter-spacing: 1px;
  margin-bottom: 1em;
  font-size: 1.3em;
}

@media (min-width: 800px) {
  #foreword section {
    padding: 7vw;
  }

  #foreword div {
    padding: 0;
  }

  #foreword p:first-of-type {
    margin-top: 1em;
  }

  #foreword picture {
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
  }

  #foreword img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  #foreword blockquote {
    z-index: 1;
    color: #fff;
    padding: 1vw 7vw 3vw;
    font-size: 2em;
    font-weight: 100;
    position: absolute;
    bottom: 0;
    left: 50%;
  }

  #foreword blockquote:before {
    top: -1vw;
    left: 5vw;
  }

  #foreword blockquote span {
    display: block;
  }
}

#services {
  border-bottom: 1px solid var(--color-black);
  position: relative;
}

.services-list {
  padding: 0;
}

#services dl {
  height: 100%;
  text-align: center;
  padding: 14vw 1vw;
}

#services dt svg {
  max-height: 1.5em;
  margin-bottom: 1.5em;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

#services dl:first-of-type {
  background-color: var(--color-blue);
  background-image: linear-gradient(to bottom, #193f60, var(--color-blue) 35%);
}

#services dl:nth-of-type(2) {
  color: #5e5e5e;
  background-color: #f8f8f8;
  background-image: linear-gradient(135deg, #c1c1c1, #fff 35%);
}

#services dl:nth-of-type(3) {
  background-color: #3375ad;
  background-image: linear-gradient(to right, #3375ad, #23567d);
}

#services dl:nth-of-type(4) {
  background-color: var(--color-white);
  color: var(--color-blue);
}

#services dl:nth-of-type(5) {
  background-color: var(--color-blue);
  color: var(--color-white);
}

#services dl:nth-of-type(6) {
  background-image: url("photo-switzerland-mountains-portrait.49a3db3b.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

#services dt {
  width: max-content;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 2em;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.3em;
  font-weight: 400;
}

#services dd {
  width: max-content;
  letter-spacing: 1px;
  margin-bottom: 1.5em;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 800px) {
  #services dl {
    padding: 7vw 1vw;
  }
}

#contacts {
  background-color: #000;
  background-image: url("background.e11b3485.jpg");
  background-size: cover;
  align-items: flex-end;
  position: relative;
}

#contacts div, #contacts address {
  padding: 14vw 7vw;
}

#contacts h2 {
  max-width: 9em;
  letter-spacing: 2px;
  margin-bottom: .5em;
  font-size: 2.3em;
  font-weight: 100;
  line-height: 1.2;
}

#contacts p {
  color: var(--color-blue);
}

#contacts address {
  font-style: normal;
}

#contacts a, #contacts span {
  color: var(--color-white);
  display: block;
}

#contacts div a {
  width: 1em;
  margin-bottom: 4em;
  display: block;
}

#contacts address svg {
  width: 1.5em;
  height: 1.5em;
  margin-top: 1.5em;
}

#contacts:after {
  content: "";
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: .1;
  background-image: url("schonbachler-pieces.0d714c8d.svg");
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
}

@media (min-width: 800px) {
  #contacts {
    min-height: 100vh;
  }

  #contacts div, #contacts address {
    padding: 7vw;
  }

  #contacts h2 {
    font-size: 3em;
  }

  #contacts div a {
    position: absolute;
    top: 7vw;
    left: 7vw;
  }

  #contacts address {
    text-align: right;
  }
}



/*# sourceMappingURL=index.7fa12614.css.map */

@font-face {
    font-family: 'Apex Sans Book';
    font-style: normal;
    font-weight: 100;
    src: url('../../fonts/ApexNew-Light.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Apex Sans Book';
    font-style: normal;
    font-weight: 400;
    src: url('../../fonts/ApexNew-Book.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Apex Sans Book';
    font-style: normal;
    font-weight: 600;
    src: url('../../fonts/ApexNew-Medium.woff') format('woff');
    font-display: swap;
}

:root {
    --default-font-size: 16px;
    --color-white: #fff;
    --color-grey: #999;
    --color-black: #101010;
    --color-blue: #008CD1;
    --color-blue-light: #00a1ff;

    --break-mobile: 640px;
}

@media (min-width: 1280px) {
    :root {
        --default-font-size: 18px;
    }
}

@media (min-width: 1600px) {
    :root {
        --default-font-size: 20px;
    }
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    font-size: var(--default-font-size);
    font-weight: 600;
    background-color: var(--color-black);
    color: var(--color-white);
    scroll-behavior: smooth;
}

html:focus-within {
    scroll-behavior: smooth;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
li,
figure,
figcaption,
blockquote,
dl,
dd {
    margin: 0;
}

body {
    font-family: 'Apex Sans Book', sans-serif;
    font-size: 100%;
    font-weight: 600;
    min-height: 100vh;
    line-height: 1.65;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

section {
    padding: 14vw 7vw;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
}

h2 {
    margin-bottom: 1em;
    font-size: 0.9em;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 2px;
}

p {
    font-weight: 600;
}

ul[role='list'],
ol[role='list'] {
    list-style: none;
}

a:not([class]) {
    text-decoration-skip-ink: auto;
}

picture {
    height: 100%;
}

img {
    display: block;
    width: 100%;
    height: auto;
}

svg {
    width: 100%;
    height: auto;
}

input,
button,
textarea,
select {
    font: inherit;
}

footer {
    position: relative;
    padding: 14vw 7vw;
    background-color: var(--color-white);
    color: var(--color-grey);
    font-size: 12px;
}

footer p {
    padding: .5em 0;
    color: var(--color-grey);
    font-size: 12px;
    line-height: 1.2;
}

@media (min-width: 800px) {
    section {
        padding: 7vw;
    }

    footer {
        padding: 7vw;
    }
}

@media (prefers-reduced-motion: reduce) {
    html {
		scroll-behavior: auto;
	}

    *,
    *::before,
    *::after {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}
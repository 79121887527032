#contacts {
    position: relative;
    background-color: rgb(0, 0, 0);
    background-image: url("../../img/background.jpg");
    background-size: cover;
    align-items: flex-end;        
}

#contacts div,
#contacts address {
    padding: 14vw 7vw;
}

#contacts h2 {
    max-width: 9em;
    margin-bottom: 0.5em;
    font-size: 2.3em;
    font-weight: 100;
    letter-spacing: 2px;
    line-height: 1.2;
}

#contacts p {
    color: var(--color-blue);
}

#contacts address {
    font-style: normal;
}

#contacts a,
#contacts span {
    display: block;
    color: var(--color-white);
}

#contacts div a {
    display: block;
    width: 1em;
    margin-bottom: 4em;
}

#contacts address svg {
    width: 1.5em;
    height: 1.5em;
    margin-top: 1.5em;
}

#contacts:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background-image: url('../../img/schonbachler-pieces.svg');
    background-size: cover;
    opacity: 0.1;
}

@media (min-width: 800px) {
    #contacts {
        min-height: 100vh;
    }

    #contacts div,
    #contacts address {
        padding: 7vw;
    }

    #contacts h2 {
        font-size: 3em;
    }
 
    #contacts div a {
        position: absolute;
        top: 7vw;
        left: 7vw;
    }

    #contacts address {
        text-align: right;
    }
}
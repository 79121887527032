#foreword {
    position: relative;
    background-color: var(--color-white);
    color: var(--color-black);
    border-bottom: 1px solid var(--color-black);
}

#foreword section {
    padding: 0;
}

#foreword div {
    padding: 14vw 7vw;
}

#foreword h2 {
    margin-bottom: 0;
}

#foreword blockquote {
    position: relative;
    padding-top: 1em;
    margin-bottom: 1em;
    font-size: 1.5em;
    font-weight: 400;
    letter-spacing: 2px;
    text-transform: uppercase;
}

#foreword blockquote:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 3em;
    height: .25em;
    background-color: var(--color-blue-light);
}

#foreword blockquote span {
    font-weight: 600;
    color: var(--color-blue);
}

#foreword blockquote span:before {
    content: "\" ";
    font-style: italic;
}

#foreword blockquote:after {
    content: "\"";
    font-style: italic;
}

#foreword p:first-of-type {
    margin-bottom: 1em;
    font-size: 1.3em;
    letter-spacing: 1px;
}

@media (min-width: 800px) {
    #foreword section {
        padding: 7vw;
    }

    #foreword div {
        padding: 0;
    }

    #foreword p:first-of-type {
    margin-top: 1em;
    }

    #foreword picture {
        position: absolute;
        top: 0;
        right: 0;
        width: 50%;
        height: 100%;
    }

    #foreword img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    #foreword blockquote {
        position: absolute;
        z-index: 1;
        left: 50%;
        bottom: 0;
        padding: 1vw 7vw 3vw;
        font-size: 2em;
        font-weight: 100;
        color: #fff;
    }

    #foreword blockquote:before {
        top: -1vw;
        left: 5vw;
    }

    #foreword blockquote span {
        display: block;
    }
}
.grid-1\/6 {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    gap: 0px 0px;
}

@media (min-width: 480px) {
    .grid-1\/6 {
        grid-template-columns: repeat(3, 1fr);
    }    
}

@media (min-width: 1024px) {
    .grid-1\/6 {
        grid-template-columns: repeat(6, 1fr);
    }    
}


@media (min-width: 800px) {
    .grid-1\/2 {
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        gap: 0px 0px;
        grid-template-areas: ". .";
    }

    .grid-2\/5 {
        display: grid;
        align-items: center;
        grid-template-columns: 2fr 5fr;
        grid-template-rows: 1fr;
        gap: 0px 0px;
        grid-template-areas: ". .";
    }

    .grid-inverted section {
        order: 2;
    }

    .grid-inverted picture {
        order: 1;
    }
}